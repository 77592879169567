<template>
  <div class="moduleTutorialWrap">
    <div class="content">
      <p class="title">使用场景</p>
      <article class="valueItem">
        <p class="itemTitle"></p>
        <p class="itemValue">
          在国际化发展战略下，企业面向多语言的国际市场，活动需要采用中英双语双流形式；进行会议信息展示与直播内容传播，观众需根据语言偏好自由选择语言报名与观看直播
        </p>
      </article>
      <article class="valueItem">
        <p class="itemTitle"></p>
        <p class="itemValue">
          <span style="display: block; font-weight: bold">配置说明：</span>
          通过双语专题将多个中英直播间两两关联建组，多个组统一报名，报名后组下活动均可观看。中英表单字段可独立设置，观众可手动选择或报名全部活动，还可将专题添加至单场大会、系列会，实现更炫酷大会效果。
        </p>
      </article>
    </div>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="less" scoped>
  .moduleTutorialWrap {
    background-image: url('../../../common/images/introduce.png');
    background-size: cover;
    height: 500px;
    margin-top: -40px;
  }
  .content {
    padding: 146px 0 0 445px;
    .title {
      width: 95px;
      height: 24px;
      background: #fb3d32;
      border-radius: 12px;
      color: #ffffff;
      line-height: 24px;
      font-size: 16px;
      text-align: center;
    }
    .valueItem {
      .itemTitle {
        width: 33px;
        height: 3px;
        margin-top: 22px;
        // background-image: linear-gradient(to right, color-stop1, color-stop2);

        background: linear-gradient(270deg, rgba(255, 255, 255, 0.2) 0%, #fb3b32 100%);
      }
      .itemValue {
        width: 230px;
        padding-top: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #1a1a1a;
        line-height: 20px;
      }
    }
  }
</style>
